<template>
    <div>
        <div class="header">
            <Row>
                <Col span="8">
                    <h1 class="title">{{ $t('navigation.goods') }}
                        ({{ total }})
                    </h1>
                </Col>
                <Col span="16" class="clearfix">
                    <Dropdown class="float-right">
                        <Button type="primary">
                            {{ $t('pages.goods.more') }}
                            <Icon type="ios-arrow-down"></Icon>
                        </Button>
                        <template #list>
                            <DropdownMenu>
                                <DropdownItem @click.native="handleAddCommodity">{{ $t('pages.goods.add') }}</DropdownItem>
                                <DropdownItem @click.native="handleFieldsModal">{{ $t('pages.goods.fieldsMag') }}</DropdownItem>
                                <DropdownItem v-allow="'account'" @click.native="handleAutoImport">{{ $t('pages.goods.connectStore') }}</DropdownItem>
                            </DropdownMenu>
                        </template>
                    </Dropdown>
                    <Button class="float-right margin-right" type="primary" @click="handlePanels">{{ $t('pages.goods.panelsMag') }}</Button>
                    <Input v-model="query" class="float-right margin-right" clearable :placeholder="$t('pages.goods.inputToSearch')" style="width: auto;" @keyup.native.enter="searchNew" @on-clear="searchNew" >
                        <Icon class="pointer" type="ios-search" slot="suffix" @click="searchNew" />
                    </Input>
                </Col>
            </Row>
        </div>
        <div class="main-content">
            <div class="action-bar">
                <div v-if="checkAllGroup.length !== 0">
                    <Button class="margin-right" type="primary" @click="handleRemove(checkAllGroup)" >{{ $t('common.del') }}</Button>
                    <Button type="primary" @click="handleBindingModal" >{{ $t('pages.goods.quickPost') }}</Button>
                </div>
                <div v-else>
                    <Dropdown v-if="is_org" class="dropdown" :visible="visible" trigger="custom" placement="bottom-start" @on-visible-change="handleTreeDropdown" @on-clickoutside="visible = false">
                        <Icon v-if="nodeList.length" class="close" type="ios-close-circle" @click="clearNodeList2" />
                        <Button type="text" @click.native="visible = true">
                            {{ $t('navigation.structure') }}
                            <div v-if="nodeList.length" class="tree line-overflow">
                                <span>:</span>
                                <span v-for="(item,index) in nodeList" :key="item.department_id">{{ item.title }}
                                    <span v-if="index !== nodeList.length - 1">,</span>
                                </span>
                            </div>
                            <Icon type="ios-arrow-down"></Icon>
                        </Button>
                        <DropdownMenu slot="list">
                            <Tree :data="data" @on-check-change="handleTree" @on-select-change="handleSelectChange"></Tree>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <Checkbox
                    v-show="list.length !== 0"
                    class="checkbox-all"
                    :indeterminate="indeterminate"
                    :value="checkAll"
                    @click.prevent.native="handleCheckAll">
                    <span v-if="checkAllGroup.length === 0">{{ $t('common.selectAll') }}</span>
                    <span v-else>{{ $t('common.selected') }}{{ checkAllGroup.length }}{{ $t('pages.space.unit') }}{{ $t(checkAllGroup.length>1?'pages.space.contents':'pages.space.content') }}</span>
                </Checkbox>
            </div>
            <no-data v-show="list.length === 0" :text="$t('pages.goods.noData')"></no-data>
            <div v-show="list.length !== 0" class="checkbox-group-wrap">
                 <CheckboxGroup class="checkbox-group" v-model="checkAllGroup" @on-change="checkAllGroupChange">
                    <div class="checkbox-wrap" v-for="item in list" :key="item.rowid" >
                        <div class="img-wrap-block">
                            <div class="img-wrap image-center">
                                <img class="image-center" :src="`/cym/${item.data.banner_picture?item.data.banner_picture.url:''}`">
                            </div>
                        </div>
                        <div class="info-block">
                            <h2 class="goods-name line-overflow">{{ item.data.name }}</h2>
                            <p class="code">{{ item.data.code }}</p>
                            <p class="price">¥&nbsp;{{ item.data.price }}</p>
                            <span v-if="item.data.tagStr" class="label line-overflow">{{ item.data.tagStr }}</span>
                        </div>
                        <div v-if="checkAllGroup.length === 0" class="mask pointer" @click="handleEdit(item.rowid)"></div>
                        <Checkbox :class="['checkbox', checkAllGroup.length === 0 ? '' : 'checked']" :label="item.rowid" ><span>&nbsp;</span></Checkbox>
                        <Dropdown v-if="checkAllGroup.length === 0" class="more" placement="bottom-start" trigger="click" >
                            <a href="javascript:void(0)">
                                <Button icon="md-more" shape="circle"></Button>
                            </a>
                            <DropdownMenu slot="list" >
                                <DropdownItem @click.native="handleRemove([item.rowid])">{{ $t('common.del') }}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </CheckboxGroup>
                <Spin fix size="large" v-if="loading"></Spin>
                <Page
                    class="page"
                    :total="total"
                    :current="current"
                    :page-size="pageSize"
                    show-elevator
                    @on-change="nextPage" />
            </div>
        </div>
        <fields-modal
            :show="fieldsModalShow"
            :bucket_token="bucket_token"
            @cancel="handleFieldsCancel"></fields-modal>
        <commodity-modal
            :show="commodityModalShow"
            :bucket_token="bucket_token"
            :rowid="commodityRowid"
            :state="commodityState"
            :is_org="is_org"
            @cancel="handleCommodityCancel"
            @confirm="handleCommodityConfirm"></commodity-modal>
        <departments-modal
            :show="departmentsModalShow"
            @refresh="handleRefreshTreeData"
            @cancel="handleDepartmentsModalCancel"></departments-modal>
        <passcode-modal
            :show="passcodeModalShow"
            :department_id="department_id"
            @cancel="handlePasscodeModalCancel"></passcode-modal>
        <panels-modal
            :show="panelsModalShow"
            :bucket_token="bucket_token"
            @cancel="handlePanelsCancel"></panels-modal>
        <binding-modal
            :show="bindingModalShow"
            :commodityList="checkAllGroup"
            @cancel="handleBindingModalCancel"
            @confirm="handleBindingModalConfirm"></binding-modal>
    </div>  
</template>
<script>
    import noData from '@/views/components/noData'
    import fieldsModal from './components/fieldsModal'
    import commodityModal from './components/commodityModal'
    import departmentsModal from './components/departmentsModal'
    import passcodeModal from './components/passcodeModal'
    import panelsModal  from './components/panelsModal'
    import bindingModal from './components/bindingModal'
    import { checkbox, dropdownTree } from '@/mixins/index'

    export default {
        name: "",
        mixins: [checkbox, dropdownTree],
        components: {
            noData,
            fieldsModal,
            commodityModal,
            departmentsModal,
            passcodeModal,
            panelsModal,
            bindingModal
        },
        props: {},
        data () {
            return {
                total: 0,
                query: '',
                current: 1,
                pageSize: 40,
                loading: false,
                list: [],
                checkAllGroup: [],
                indeterminate: false,
                checkAll: false,
                nodeList: [],
                treeList: [],
                data: [],
                fieldsModalShow: false,
                commodityModalShow: false,
                commodityRowid: null,
                commodityState: 'add',
                departmentsModalShow: false,
                passcodeModalShow: false,
                visible: false,
                tree_data: [],
                panelsModalShow: false,
                bindingModalShow: false,

            }
        },
        computed: {
            is_org() {
                return this.$store.state.default_bucket_tokens.org
            },
            bucket_token() {
                let org = this.$store.state.default_bucket_tokens.org
                if (org) return org
                return this.$store.state.default_bucket_tokens.user
            },
            department_id() {
                return this.$store.state.user_tree.department_id
            }
        },
        methods: {
            handlePasscode() {
                this.passcodeModalShow = true
            },
            handlePasscodeModalCancel() {
                this.passcodeModalShow = false
            },
            handleAutoImport() {
                if (this.is_org) {
                    this.departmentsModalShow = true
                } else {
                    this.passcodeModalShow = true
                }
            },
            handleDepartmentsModalCancel() {
                this.departmentsModalShow = false
            },
            searchNew() {
                if (this.loading == true) return
                this.current = 1
                this.getData()
            },
            clearNodeList2() {
                this.nodeList = []
                this.data = this.handleData(this.tree_data)
                this.visible = false
                this.getData()
            },
            handleEdit(rowid) {
                this.commodityState = 'edit'
                this.commodityRowid = rowid
                this.commodityModalShow = true
            },
            handleRemove(list) {
                let _this = this
                this.$Modal.confirm({
                    title: this.$t('common.tip'),
                    content: `<p>${this.$t('pages.goods.delCon')}</p><p>${this.$t('pages.goods.delCon2')}</p>`,
                    okText: this.$t('common.confirm'),
                    cancelText: this.$t('common.cancel'),
                    closable: true,
                    onOk: () => {
                        setTimeout(function() {
                            _this.$Modal.confirm({
                                title: _this.$t('common.confirmDel'),
                                content: _this.$t('pages.goods.delCon2'),
                                okText: _this.$t('common.confirm'),
                                cancelText: _this.$t('common.cancel'),
                                loading: true,
                                closable: true,
                                onOk: () => {
                                    _this.$api.delete(`commodities/batch_delete?bucket_token=${_this.bucket_token}&rowid_list=${JSON.stringify(list)}`).then(() => {
                                        _this.checkAllGroup = []
                                        _this.indeterminate = false
                                        _this.checkAll = false
                                        _this.getData()
                                    }).finally(() => {
                                        _this.$Modal.remove()
                                    })
                                }
                            })
                        }, 500)
                    }
                })
            },
            handleBindingModal() {
                this.bindingModalShow = true
            },
            handleBindingModalCancel() {
                this.bindingModalShow = false
            },
            handleBindingModalConfirm() {
                this.checkAllGroup = []
                this.handleBindingModalCancel()
            },
            handleFieldsModal() {
                this.fieldsModalShow = true
            },
            handleFieldsCancel() {
                this.fieldsModalShow = false
            },
            handleCommodityCancel() {
                this.commodityModalShow = false
            },
            handleCommodityConfirm() {
                this.handleCommodityCancel()
                this.getData()
            },
            handleAddCommodity() {
                this.commodityState = 'add'
                this.commodityModalShow = true
                this.commodityRowid = null
            },
            handlePanels() {
                this.panelsModalShow = true
            },
            handlePanelsCancel() {
                this.panelsModalShow = false
            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            getData() {
                let params = {
                        bucket_token: this.bucket_token,
                        query: this.query,
                        start: (this.current - 1) * this.pageSize,
                        count: this.pageSize
                    },
                    len = this.nodeList.length;
                if (len) {
                    let bucket_token_list = []
                    this.nodeList.map((item) => {
                        bucket_token_list.push(item.bucket_token)
                    })
                    params.bucket_token = bucket_token_list[0]
                }
                this.loading = true
                this.checkAll = false
                this.indeterminate = false

                this.$api.get('commodities/search', { params }).then(({ data=[], total_count=0, page=1 }) => {
                    if (page === this.current) {
                        let isAll = true
                        //检测是否当页全选，当前页全勾选，显示全选状态
                        data.forEach((item)=>{
                            if (this.checkAllGroup.indexOf(item.rowid)== -1) {
                                isAll = false
                            } else {
                                this.indeterminate = true
                            }
                            let str = ''
                            if (item.data.tag && item.data.tag.length) {
                                for (let i=0, len=item.data.tag.length; i<len; i++ ) {
                                    str += item.data.tag[i].name
                                    if (i !=  len - 1) {
                                        str += ','
                                    }
                                }
                            }
                            if (item.data.price !== null) item.data.price = typeof item.data.price==='number'?item.data.price.toFixed(2):item.data.price
                            this.$set(item.data, 'tagStr', str)
                        })

                        if (isAll) {
                            this.indeterminate = false;
                            this.checkAll = true;
                        }

                        this.list = data
                        this.total = total_count
                        this.loading = false
                    }
                }).catch(()=>{
                    this.loading = false
                }).finally(()=>{})
            },
            handleData(obj) {
                let newObj = obj.constructor === Array ? []:{};
                if(typeof obj !== 'object'){
                    return
                }else{
                    newObj.disabled = obj.has_passcode?false:true
                    newObj.expand = true
                    newObj.title = obj.has_passcode?`${obj.name}(${this.$t('pages.goods.connected')})`:obj.name
                    for(let i in obj){
                        if(Object.prototype.hasOwnProperty.call(obj, i)){
                            newObj[i] = typeof obj[i] === 'object'?this.handleData(obj[i]):obj[i];
                        }
                    }
                }
                return newObj
            },
            getTree() {
                return this.$api.get('organizations/current/departments/tree_with_open_api_passcode').then(({ data }) => {
                    this.tree_data = data
                    this.data = this.handleData(data)
                }).catch(() => {})
            },
            async handleRefreshTreeData() {
                await this.getTree()
                this.clearNodeList2()
            }
        },
        mounted() {
            if (this.is_org) this.getTree()
            this.getData()
        }
    }
</script>
<style scoped lang="less">
@import '~less/color';
.action-bar {
    position: relative;
    .dropdown {
        position: relative;
        .close {
            display: none;
        }
        &:hover {
            .close {
                display: block;
                position: absolute;
                right: 0px;
                top: 0px;
            }
        }
        .tree {
            display: inline-block;
            max-width: 100px;
            vertical-align: middle;
        }
    }
    .checkbox-all {
        position: absolute;
        top: 0px;
        right: 0px;
    }
    .dropdown-label {
        margin-left: -15px;
        .tooltip {
            display: block;
            width: 100%;
            .label {
                max-width: 179px;
            }
        }
    }
}
.checkbox-group-wrap {
    position: relative;
    .checkbox-group {
        width: calc(100% + 16px);
        .checkbox-wrap {
            position: relative;
            display: inline-block;
            margin: 0 16px 31px 0;
            padding-left: 0.7%;
            border: 1px solid #d3d3d3;
            width: calc(25% - 16px);
            padding-top: 15.2%;
            background: #f0f3f4;
            &:hover {
                .mask {
                    opacity: 1;
                }
                .checkbox, .more {
                    display: inline-block;
                }
            }
            .mask {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,.08);
                transition: opacity .3s ease;
            }
            .checkbox {
                display: none;
                position: absolute;
                top: 0;
                right: 0;
                margin-right: 0;
                padding: 5px;
                text-align: right;
            }
            .checked {
                display: inline-block;
                width: 100%;
                height: 100%;

            }
            .more {
                display: none;
                position: absolute;
                right: 3%;
                bottom: 3%;
            }
            .img-wrap-block {
                position: absolute;
                left: 4.6%;
                top: 50%;
                transform: translateY(-50%);
                display: flex;
                align-items: center;
                width: 52%;
                padding-top: 52%;
                background: #fff;
                border-radius: 5px;
                .img-wrap {
                    width: 100%;
                    padding-top: 57.4%;
                    .device-status {
                        position: absolute;
                        left: 5%;
                        bottom: 10%;
                        width: 47px;
                        height: 18px;
                        padding: 0 5px;
                        border-radius: 4px;
                        background: #000;
                        color: #fff;
                        line-height: 18px;
                        .text {
                            position: absolute;
                            left: 15px;
                            top: 50%;
                            transform: translateY(-50%);
                            font-size: 10px;
                        }
                    }
                }
            }
            .info-block {
                position: absolute;
                top: 49%;
                left: 60%;
                transform: translateY(-50%);
                width: 39.8%;
                .goods-name {
                    font-size: 14px;
                    line-height: 20px;
                }
                .code {
                    overflow: hidden;
                    font-size: 10px;
                    line-height: 16px;
                }
                .price {
                    margin: 8px 0;
                    font-size: 12px;
                    line-height: 20px;
                    color: #bc6347;
                }
                .label {
                    display: inline-block;
                    padding: 2px 12px;
                    max-width: 90%;
                    background: @primary-color;
                    border-radius: 4px;
                    color: #ffffff;
                    text-align: center;
                    font-size: 10px;
                    line-height: 20px;
                }
            }
        }
        @media screen and (min-width: 1600px) {
            .checkbox-wrap {
                .info-block {
                    .goods-name {
                        font-size: 20px;
                        line-height: 29px;
                    }
                    .code {
                        font-size: 14px;
                        line-height: 22px;
                    }
                    .price {
                        font-size: 18px;
                        line-height: 29px;
                    }
                    .label {
                        font-size: 14px;
                        line-height: 29px;
                    }
                }
            }
        }
    }
}
</style>