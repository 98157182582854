<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="state==='add'?$t('pages.goods.addPanel'):$t('pages.goods.editPanel')"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange">
        <Form class="formItem" ref="formItem" :model="formItem" :rules="ruleValidate" :label-width="80">
            <FormItem v-if="state==='add'" label="KEY" prop="key">
                <Input v-model.trim="formItem.key" show-word-limit :maxlength="32" :placeholder="$t('pages.goods.inputPanelKey')"></Input>
            </FormItem>
            <FormItem :label="state==='add'?$t('pages.goods.panelName'):''" prop="name">
                <Input v-model.trim="formItem.name" show-word-limit :maxlength="32" :placeholder="$t('pages.goods.inputPanelName')"></Input>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" :loading="loading" @click="submit">{{ $t('common.confirm') }}</Button>
        </div>
    </Modal>
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            state: {
                default: 'add'
            },
            editInfo: {
                default: () => {
                    return {}
                }
            },
            bucket_token: {
                default: ''
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                formItem: {
                    key: '',
                    name: ''
                }
            }
        },
        computed: {
            ruleValidate() {
                return {
                    key: [
                        {
                            required: true, pattern: /^[0-9a-zA-Z]+$/, message: this.$t('pages.goods.inputDigitsAndLetters'), trigger: 'blur'
                        }
                    ],
                    name: [
                        {
                            required: true, message: this.$t('common.input'), trigger: 'blur'
                        }
                    ]
                }
            },
            lang() {
                return this.$store.state.lang
            }
        },
        watch: {
            show(val) {
                this.modal = val
                if (val && this.state === 'edit') {
                    this.formItem.key = this.editInfo.key
                    this.formItem.name = this.editInfo.text[this.lang]?this.editInfo.text[this.lang]:this.editInfo.text._
                }
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.$emit('cancel')
                this.$refs.formItem.resetFields()
                this.formItem = {
                    key: '',
                    name: ''
                }
            },
            submit() {
                this.$refs.formItem.validate((valid) => {
                    if (valid) {
                        this.loading = true
                        if (this.state === 'add') {
                            this.$api.post(`data_panels/${this.formItem.key}`, { text: { _: this.formItem.name }, bucket_token: this.bucket_token }).then(() => {
                                this.$emit('confirm')
                                this.cancel()
                            }).catch(() => {}).finally(() => {
                                this.loading = false
                            })
                        } else {
                            this.$api.put(`data_panels/${this.formItem.key}`, { text: { _: this.formItem.name }, bucket_token: this.bucket_token }).then(() => {
                                this.$emit('confirm')
                                this.cancel()
                            }).catch(() => {}).finally(() => {
                                this.loading = false
                            })
                        }
                    }
                })
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
.formItem {
    width:70%;
}
</style>