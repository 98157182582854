<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="$t('pages.goods.selectFile')"
        :width="387"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange"
    >
        <div v-if="list.length">{{ $t('pages.goods.provideTip') }}{{ source==='exclusive_gallery_commodity'?$t('pages.goods.exclusiveImage'):$t('pages.goods.exclusiveVideo') }}，{{ $t('pages.goods.useDirectly') }}</div>
        <ul class="image-section">
            <li v-if="list.length" class="image-item">
                <img class="image-center" :src="list[0].value">
            </li>
        </ul>
        <div slot="footer">
            <Button v-if="list.length" class="margin-right" type="primary" @click="handleUse">{{ $t('pages.goods.use') }}</Button>
            <Upload
                ref="upload"
                class="upload"
                action=""
                :before-upload="handleBeforeUpload">
                <Button type="primary" :loading="loading">{{ $t('pages.goods.selecLocalFile') }}</Button>
            </Upload>
        </div>
        <failed-modal
            :show="failedModalShow"
            :list="block_list"
            @cancel="hideFailedModal"
        ></failed-modal>
    </Modal>
</template>
<script>
    import failedModal from '@/views/components/failedModal'

    export default {
        name: "",
        mixins: [],
        components: {
            failedModal
        },
        props: {
            show: {
                default: false
            },
            source: {
                default: ''
            },
            name: {
                default: ''
            },
            bucket_token: {
                default: ''
            },
            fields: {
                default: () => {
                    return {}
                }
            }
        },
        data () {
            return {
                modal: false,
                list: [],
                block_list: [],
                failedModalShow: false,
                timer: null,
                switch: false,
                rowid_list: [],
                defaultList: [],
                formats: [],
                loading: false,
                videos: ['qt', 'ts', 'vob', 'webm', 'swf', 'mj2', 'mjpeg', 'mpe', 'm3u8', 'm4v', 'm2t', 'f4v', 'dv','rm', 'rmvb', '3gp', 'avi', 'mpeg', 'mpg', 'mkv', 'dat', 'asf', 'wmv', 'flv', 'mov', 'mp4', 'ogg'],
                images: ['jpg', 'png', 'jpeg', 'mjpeg', 'bmp']
            }
        },
        computed: {},
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    if (this.source === 'exclusive_video_commodity') {
                        this.formats = this.videos
                    } else if (this.source === 'exclusive_gallery_commodity' || this.source === 'exclusive_descriptions_commodity') {
                        this.formats = this.images
                    } else {
                        for (let k in this.fields) {
                            if (k === this.source) {
                                if (this.fields[k].type === 'video') this.formats = this.videos
                                if (this.fields[k].type === 'image') this.formats = this.images
                                break
                            }
                        }
                    }
                    if (this.source === 'exclusive_video_commodity' || this.source === 'exclusive_gallery_commodity' || this.source === 'exclusive_descriptions_commodity') {
                        this.getData()
                    }
                }
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.block_list = []
                this.defaultList = []
                this.rowid_list = []
                this.list = []
                this.$emit('cancel')
            },
            getData() {
                let params = {
                    category: this.name,
                    source: this.source
                }
                this.$api.get('open_materials/search_by_category', { params }).then(({ data=[] }) => {
                    if (data.length) {
                        data[0].value = `/cym/${data[0].value}/thumbnail`
                        this.list = [data[0]]
                    }
                })
            },
            handleBeforeUpload(file) {
                let formData = new FormData(),
                    name = file.name.match(/(.*)\..*$/)[1].slice(0, 32),
                    format = file.name.match(/.*\.(.*)/)[1].toLowerCase();
                formData.append(name, file)
                formData.append('bucket_token', this.bucket_token)
                if (this.formats.indexOf(format) === -1) {
                    this.$Message.error(this.$t('pages.goods.unsupportedFormat'))
                    return false
                }
                this.loading = true
                this.$api.post('materials', formData).then(({ pass_list, rowid_list }) => {
                    let params = {
                        name: name,
                        cover_url: require('../../../../assets/video.png'),
                    }
                    if (this.source !== 'exclusive_video_commodity') {
                        params.cover_url = URL.createObjectURL(file)
                    }
                    if (this.source !== 'exclusive_video_commodity' && this.source !== 'exclusive_gallery_commodity' && this.source !== 'exclusive_descriptions_commodity') {
                        for (let k in this.fields) {
                            if (k === this.source) {
                                if (this.fields[k].type === 'video') params.cover_url = require('../../../../assets/video.png')
                                break
                            }
                        }
                    }
                    this.defaultList.push(params)
                    if (pass_list && pass_list.length) {
                        this.switch = false
                    } else {
                        this.switch = true
                        this.rowid_list.push(...rowid_list)
                    }
                    if (!this.switch) {
                        let _this = this
                        setTimeout(function() {
                            let params = {
                                rowid: rowid_list[0],
                                value: _this.defaultList[0].cover_url,
                                name: _this.defaultList[0].name
                            }
                            _this.$emit('confirm', params)
                            _this.cancel()
                        }, 500)
                        return
                    }
                    clearInterval(this.timer)

                    this.timer = setInterval(this.check_upload_status, 1000)
                }).catch(()=>{
                }).finally(() => {
                    this.loading = false
                })
                return false
            },
            check_upload_status() {
                const params = {
                    bucket_token: this.bucket_token,
                    rowid_list: JSON.stringify(this.rowid_list)
                }
                this.$api.get('materials/check_upload_status', { params }).then(({ block_list, rowid_list }) => {
                    if (rowid_list.length === this.rowid_list.length) {
                        clearInterval(this.timer)
                        this.block_list.push(...block_list)
                        let len_block = this.block_list.length,
                            len_default = this.defaultList.length;
                        if (len_block) {
                            //处理被拦截数据展示
                            for (let i = 0; i < len_block; i++) {
                                for  (let j  = 0; j < len_default; j++) {
                                    if (this.block_list[i].name === this.defaultList[j].name) {
                                        this.block_list[i].cover_url = this.defaultList[j].cover_url
                                        break
                                    }
                                }
                            }
                            this.failedModalShow = true
                        } else  {
                            let _this = this
                            setTimeout(function() {
                                let params = {
                                    rowid: _this.rowid_list[0],
                                    value: _this.defaultList[0].cover_url,
                                    name: _this.defaultList[0].name
                                }
                                _this.$emit('confirm', params)
                                _this.cancel()
                                // _this.loadingModalShow = false
                            }, 500)
                            this.loading = false
                        }
                    }
                }).catch(() => {}).finally(() => {
                    this.loading = false
                })
            },
            hideFailedModal() {
                this.failedModalShow = false
                this.loading = false
                this.cancel()
            },
            handleUse() {
                this.$api.post('materials/copy', { rowid_list: [this.list[0].rowid], dist_bucket_token: this.bucket_token }).then(({ result_rowid_dict }) => {
                    let key = this.list[0].rowid.toString()
                    this.list[0].rowid = result_rowid_dict[key]
                    this.$emit('confirm', this.list[0])
                    this.cancel()
                })
            }
        },
        beforeDestroy() {
            clearInterval(this.timer)
        },
        created() {}
    }
</script>
<style scoped lang="less">
.image-section {
    margin: 0 auto;
    .image-item {
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin: 0 auto;
        width: 80px;
        height: 80px;
        border: 1px solid #808080;
        border-radius: 10px;
    }
}
.upload {
    display: inline-block;
}
</style>