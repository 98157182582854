<template>
    <Modal
        class="wrap"
        v-model="modal"
        title=""
        :width="387"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange">
        <div class="company-name">{{ department_name }}</div>
        <div class="passcode-content">{{ $t('pages.goods.connectCode') }}：{{ passcode }}
            <span class="refresh-btn pointer" @click="handleMfaCode('refresh')"><i class="iconfont icon-tihuan"></i></span>
        </div>
        <div slot="footer">
            <Button type="default" @click="handleMfaCode('clear')">{{ $t('pages.goods.clearCode') }}</Button>
            <Button type="primary" @click="handleCopy">{{ $t('pages.goods.copyCode') }}</Button>
        </div>
    </Modal>
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            department_id: {
                default: null
            },
            department_name: {
                default: ''
            }
        },
        data () {
            return {
                modal: false,
                passcode: '',
                value: ''
            }
        },
        computed: {},
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    this.getPasscode()
                }
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.passcode = ''
                this.$emit('cancel')
            },
            handleCopy() {
                this.$copyText(this.passcode).then(() => {
                    this.$Message.success(this.$t('common.copySuccess'))
                    this.cancel()
                }, () => {
                    this.$Message.error(this.$t('common.copyFailed'))
                })
            },
            clearPasscode() {
                this.$Modal.confirm({
                    closable: true,
                    loading: true,
                    okText: this.$t('pages.goods.confirmClear'),
                    content: `<p>1、${this.$t('pages.goods.clearPassCodeTip')}</p>\
                    <p>2、${this.$t('pages.goods.clearPassCodeTip2')}</p>`,
                    onOk: () => {
                        this.$api.delete(`organizations/current/departments/${this.department_id}/open_api_passcode?mfa_code=${this.value }`).then(() => {
                            this.$Message.success(this.$t('common.success'))
                            this.cancel()
                        }).catch(() => {}).finally(() => {
                            this.value = ''
                            this.$Modal.remove()
                        })
                    },
                })
            },
            getPasscode() {
                this.$api.get(`organizations/current/departments/${this.department_id}/open_api_passcode`).then(({ open_api_passcode }) => {
                    this.passcode = open_api_passcode
                }).catch(() => {})
            },
            handleRefresh() {
                this.$Modal.confirm({
                    closable: true,
                    loading: true,
                    okText: this.$t('pages.goods.confirrmRefresh'),
                    content: `<p>1、${this.$t('pages.goods.refreshTip')}</p>\
                    <p>2、${this.$t('pages.goods.refreshTip2')}</p>`,
                    onOk: () => {
                        this.$api.put(`organizations/current/departments/${this.department_id}/open_api_passcode/reset`, { mfa_code: this.value }).then(({ open_api_passcode }) => {
                            this.passcode = open_api_passcode
                        }).catch(() => {}).finally(() => {
                            this.value = ''
                            this.$Modal.remove()
                        })
                    }
                })
            },
            handleMfaCode(state) {
                let _this = this
                this.$Modal.info({
                    title: this.$t('pages.goods.dynamicCode'),
                    closable: true,
                    okText: this.$t('common.confirm'),
                    render: (h) => {
                        return h('Input', {
                            props: {
                                model: this.value,
                                autofocus: true,
                                placeholder: this.$t('common.input')
                            },
                            on: {
                                input: (val) => {
                                    this.value = val;
                                }
                            }
                        })
                    },
                    onOk: () => {
                        if (!this.value) return this.$Message.warning(this.$t('pages.login.inputCode'))
                        if (state === 'clear') {
                            setTimeout(() => {
                                _this.clearPasscode()

                            }, 500)
                        }  else if (state === 'refresh') {
                            setTimeout(() => {
                                _this.handleRefresh()
                            }, 500)
                        }
                    }
                })
            },
        },
        created() {}
    }
</script>
<style scoped lang="less">
@import "~less/color";
.company-name {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 22px;
    font-weight: 400;
    text-align: center;
}
.passcode-content {
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    text-align: center;
    .refresh-btn {
        display: inline-block;
        margin-left: 11px;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        background: @primary-color;
        .icon-tihuan {
            color: #fff;
        }
    }
}
</style>