<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="state==='edit'?$t('pages.goods.editField'):$t('pages.goods.addField')"
        :width="387"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange">
        <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
            <FormItem :label="$t('pages.goods.fieldKey')" prop="field_key">
                <Input v-model="formValidate.field_key" :disabled="state==='edit'?true:false" :placeholder="$t('common.input')"></Input>
            </FormItem>
            <FormItem :label="$t('pages.goods.fieldName')" prop="text">
                <Input v-model="formValidate.text" :placeholder="$t('common.input')"></Input>
            </FormItem>
            <FormItem :label="$t('pages.goods.fieldType')" prop="fields_type">
                <Select v-model="formValidate.fields_type" :disabled="state==='edit'?true:false" transfer :placeholder="$t('common.select')">
                    <Option v-for="(item, index) in list" :key="index" :value="item.fields_type">{{ item.text[lang]?item.text[lang]:item.text._ }}</Option>
                </Select>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" :loading="loading" @click="submit">{{ $t('common.confirm') }}</Button>
        </div>
    </Modal>
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            state: {
                default: 'add'
            },
            parameterInfo: {
                default: () => {
                    return {}
                }
            },
            bucket_token: {
                default: ''
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                list: [],
                formValidate: {
                    field_key: '',
                    text: '',
                    fields_type: ''
                }
            }
        },
        computed: {
            ruleValidate() {
                return {
                    field_key: [
                        {
                            required: true, type: 'string', message: this.$t('common.input'), trigger: 'blur'
                        }
                    ],
                    text: [
                        {
                            required: true, type: 'string', message: this.$t('common.input'), trigger: 'blur'
                        },
                        {
                            type: 'string', max: 16, message: this.$t('common.limit'), trigger: 'blur'
                        }
                    ],
                    fields_type: [
                        {
                            required: true, type: 'string', message: this.$t('common.select'), trigger: 'change'
                        }
                    ],
                }
            },
            lang() {
                return this.$store.state.lang
            }
        },
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    if (this.state === 'edit') {
                        this.formValidate = {
                            field_key: this.parameterInfo.field_key,
                            text: this.parameterInfo.text[this.lang]?this.parameterInfo.text[this.lang]:this.parameterInfo.text._,
                            fields_type: this.parameterInfo.type
                        }
                    }
                }
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.$emit('cancel')
                this.$refs.formValidate.resetFields()
            },
            submit() {
                this.$refs.formValidate.validate((valid) => {
                    if (valid) {
                        let item = this.list.find((item) => item.fields_type===this.formValidate.fields_type)
                        let params = {
                            field_key: this.formValidate.field_key,
                            data: {
                                column: 'parameters',
                                text: {
                                    _: this.formValidate.text
                                },
                                type: this.formValidate.fields_type,
                                type_text: item.text,
                                visible: true
                            }
                        }

                        this.$emit('confirm', params)
                    }
                })
            },
            getList() {
                this.$api.get('commodities/fields_type_list').then(({ data }) => {
                    this.list = data
                }).catch(() => {})
            }
        },
        mounted() {
            this.getList()
        }
    }
</script>
<style scoped>
    
</style>