<template>
    <Modal
        class="wrap"
        v-model="modal"
        footer-hide
        :title="$t('pages.goods.connectStore')"
        :width="800"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange">
         <Table class="departments-table" row-key="department_id" border :columns="columns" :data="list" :loading="loading">
            <template slot-scope="{ row }" slot="action">
                <span class="add-sub pointer margin-right" @click="handlePassCode(row)">{{ row.has_passcode?$t('pages.goods.showCode'):$t('pages.goods.generateCode') }}</span>
            </template>
        </Table>
        <p class="tip">*{{ $t('pages.goods.connectTip') }}</p>
        <passcode-modal
            :show="passcodeModalShow"
            :department_id="department_id"
            :department_name="department_name"
            @cancel="handlePasscodeModalCancel"></passcode-modal>
    </Modal>
</template>
<script>
    import passcodeModal from './passcodeModal'
    import util from '@/libs/util.js'

    export default {
        name: "",
        mixins: [],
        components: {
            passcodeModal
        },
        props: {
            show: {
                default: false
            }
        },
        data () {
            return {
                modal: false,
                list: [],
                loading: false,
                passcodeModalShow: false,
                department_id: null,
                department_name: ''
            }
        },
        computed: {
            columns() {
                return [
                    {
                        title: this.$t('navigation.structure'),
                        key: 'name',
                        align: 'left',
                        tree: true
                    },
                    {
                        title: this.$t('pages.goods.connectTime'),
                        key: 'last_online_time',
                        align: 'center'
                    },
                    {
                        title: this.$t('common.action'),
                        slot:  'action',
                        align: 'center'
                    }
                ]
            }
        },
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    this.getData()
                }
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.list = []
                this.department_name = ''
                this.department_id = null
                this.$emit('cancel')
            },
            handleData(obj) {
                let newObj = obj.constructor === Array ? []:{};
                if (obj.constructor === Object) {
                    obj._showChildren = true
                    if (obj.last_online_time === '') {
                        obj.last_online_time = '-'
                    } else {
                        obj.last_online_time = util.handleTime(obj.last_online_time)
                    }
                }
                    if(typeof obj !== 'object'){
                        return
                    }else{
                        for(let i in obj){
                            if(Object.prototype.hasOwnProperty.call(obj, i)){
                                newObj[i] = typeof obj[i] === 'object'?this.handleData(obj[i]):obj[i];
                            }
                        }
                    }
                    return newObj
            },
            getData() {
                this.$api.get('organizations/current/departments/tree_with_open_api_passcode').then(({ data }) => {
                    this.list = this.handleData(data)
                }).catch(() => {})
            },
            handlePassCode(row) {
                this.department_id = row.department_id
                this.department_name = row.name
                this.passcodeModalShow = true
            },
            handlePasscodeModalCancel() {
                this.passcodeModalShow = false
                this.getData()
                this.$emit('refresh')
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
@import "~less/color";
@deep: ~'>>>';
.departments-table @{deep} .ivu-table th, .table .ivu-table td {
    height: 60px;
}
.departments-table @{deep} thead tr th {
    background: #f8fbff;
    color: #092c4c;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
}
.add-sub {
    color: @primary-color;
}
.tip {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #AFB4BA;
    text-align: center;
}
</style>